import { useState, useMemo, useEffect } from 'react'
import { useReactTable, getCoreRowModel, getFilteredRowModel, 
    getFacetedRowModel, getFacetedUniqueValues, getFacetedMinMaxValues, 
    getPaginationRowModel, getSortedRowModel, flexRender
} from '@tanstack/react-table'
import { rankItem } from '@tanstack/match-sorter-utils'
import { Link } from "react-router-dom";

const fuzzyFilter = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value)

    addMeta({itemRank,})

    return itemRank.passed
}

const PosterListTable = ({_data, _onResp}) => {

const [columnFilters, setColumnFilters] = useState([])
const [globalFilter, setGlobalFilter] = useState('')
const columns = useMemo(
    () => [
    {
        header: ` `,
        footer: props => props.column.id,
        columns: [
            {
                accessorFn: row => row.fullName,
                id: 'fullName',
                cell: info => info.getValue(),
                header: () => <span>Name</span>,
                footer: props => props.column.id,
            },
        ],
    },
    {
        header: ` `,
        footer: props => props.column.id,
        columns: [
            {
                accessorFn: row => row.email,
                id: 'email',
                cell: info => info.getValue(),
                header: () => <span>Email ID</span>,
                footer: props => props.column.id,
            },
        ],
    },
    {
        header: ` `,
        footer: props => props.column.id,
        columns: [
            {
                accessorFn: row => row.createdAt.substring(0, 10),
                id: 'createdAt',
                cell: info => info.getValue(),
                header: () => <span>Create Date</span>,
                footer: props => props.column.id,
            },
        ],
    }
    ],
    []
)

const [data, setData] = useState(_data)
useEffect(() => {
    setData(_data)
}, [_data])

const table = useReactTable({
    data,
    columns,
    filterFns: {
        fuzzy: fuzzyFilter,
    },
    state: {
        columnFilters,
        globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: false,
    debugHeaders: false,
    debugColumns: false,
})

useEffect(() => {
    if (table.getState().columnFilters[0]?.id === 'fullName') {
        if (table.getState().sorting[0]?.id !== 'fullName') {
        table.setSorting([{id: 'fullName', desc: false }])
    }
    }
}, [table.getState().columnFilters[0]?.id])

return <>
<div className="table-responsive">
    <div className="h-2"></div>
    <table className='table table-sm table-hover table-striped'>
        <thead className='thead-dark'>
            {table.getHeaderGroups().map((headerGroup, index) => (
                <tr key={`${headerGroup.id}-${index}`}>
                    {headerGroup.headers.map(header => {
                        return (
                            <>
                                <th key={`${header.id}-x-${index}`} colSpan={header.colSpan}>
                                    {header.isPlaceholder ? null : (
                                        <>
                                            <div
                                                {...{
                                                className: header.column.getCanSort()
                                                    ? 'cursor-pointer select-none'
                                                    : '',
                                                onClick: header.column.getToggleSortingHandler(),
                                                }}
                                            >
                                                {flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                                )}
                                                {{
                                                asc: ' 🔼',
                                                desc: ' 🔽',
                                                }[header.column.getIsSorted()] ?? null}
                                            </div>
                                            {header.column.getCanFilter() ? (
                                                <div>
                                                    <Filter column={header.column} table={table} />
                                                </div>
                                            ) : null}
                                        </>
                                    )}
                                </th>
                            </>
                        )
                    })}
                    <th></th>
                    <th></th>
                    <th></th>
                </tr>
            ))}
        </thead>
        <tbody>
            {table.getRowModel().rows.map((row, _index) => {
                return (
                    <tr style={{'cursor': 'pointer'}} key={`${row.id} - ${_index}`}>
                        {row.getVisibleCells().map(cell => {
                            return (
                            <td key={cell.id} className='text-nowrap'>
                                {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                                )}
                            </td>
                            )
                        })}
                        <td>
                            {row['original']['isverified'] ? (
                                <div onClick={() => _onResp(contact.email, false)} className="btn btn-primary light" data-toggle="tooltip" data-placement="top" title="Block">
                                    <i className="fa fa-ban" aria-hidden="true"></i>{" "}
                                </div>
                            ) : (
                                <div onClick={() => {_onResp(contact.email, true);}} className="btn btn-success me-1 btn-sm" data-toggle="tooltip" data-placement="top" title="Unblock">
                                    <i className="fas fa-thumbs-up" aria-hidden="true"></i>
                                </div>
                            )}
                        </td>
                        <td>
                            <Link to={"/detail-poster"} onClick={() => _onResp('view', row['original'])} className="btn btn-success light mr-2">
                                <svg xmlns="http://www.w3.org/2000/svg" className="svg-main-icon" width="24px" height="24px" viewBox="0 0 32 32" x="0px" y="0px">
                                <g data-name="Layer 21">
                                    <path d="M29,14.47A15,15,0,0,0,3,14.47a3.07,3.07,0,0,0,0,3.06,15,15,0,0,0,26,0A3.07,3.07,0,0,0,29,14.47ZM16,21a5,5,0,1,1,5-5A5,5,0,0,1,16,21Z" fill="#000000" fillRule="nonzero"></path>
                                    <circle cx="16" cy="16" r="3" fill="#000000" fillRule="nonzero"></circle>
                                </g>
                                </svg>
                            </Link>
                        </td>
                        <td>
                            <Link to={"#"} className="btn btn-danger light" onClick={() => _onResp('remove', row['original'])}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="svg-main-icon">
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <rect x="0" y="0" width="24" height="24"></rect>
                                    <path d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z" fill="#000000" fillRule="nonzero"></path>
                                    <path d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3"></path>
                                    </g>
                                </svg>
                            </Link>
                        </td>
                    </tr>
                )
            })}
        </tbody>
    </table>

    <div className="h-2"></div>
    
    <div className="flex items-center gap-2">
        <button onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()}
            className="btn border rounded p-1">
            {'<<'}
        </button>
        <button onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}
            className="btn border rounded p-1">
            {'<'}
        </button>
        <button className="btn border rounded p-1"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}>
            {'>'}
        </button>
        <button className="btn border rounded p-1"
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}>
            {'>>'}
        </button>
        <span className="flex items-center gap-1">
            <br />
            <strong>
            Page &nbsp;
                {table.getState().pagination.pageIndex + 1} of{' '}
                {table.getPageCount()}
            </strong>
        </span>
    </div>
</div>
</>
}

function Filter({column,table,}) {
    const firstValue = table
        .getPreFilteredRowModel()
        .flatRows[0]?.getValue(column.id)

    const columnFilterValue = column.getFilterValue()
    
    const sortedUniqueValues = useMemo(
        () =>
        typeof firstValue === 'number'
            ? []
            : Array.from(column.getFacetedUniqueValues().keys()).sort(),
        [column.getFacetedUniqueValues()]
    )

    return typeof firstValue === 'number' ? (
    <div>
        <div className="flex space-x-2">
        <DebouncedInput type="number"
            min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
            max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
            value={(columnFilterValue)?.[0] ?? ''}
            onChange={value =>
                column.setFilterValue((old) => [value, old?.[1]])
            }
            placeholder={`Min ${
                column.getFacetedMinMaxValues()?.[0]
                ? `(${column.getFacetedMinMaxValues()?.[0]})`
                : ''
            }`}
            className="w-24 border shadow rounded"
        />
        <DebouncedInput
            type="number"
            min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
            max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
            value={(columnFilterValue)?.[1] ?? ''}
            onChange={value =>
                column.setFilterValue((old) => [old?.[0], value])
            }
            placeholder={`Max ${
                column.getFacetedMinMaxValues()?.[1]
                ? `(${column.getFacetedMinMaxValues()?.[1]})`
                : ''
            }`}
            className="w-24 border shadow rounded"
        />
        </div>
        <div className="h-1" />
    </div>
    ) : (
    <>
        <DebouncedInput type="text" className="w-36 border shadow rounded"
            value={(columnFilterValue ?? '')}
            onChange={value => column.setFilterValue(value)}
            placeholder={`Search... (${column.getFacetedUniqueValues().size})`}
            list={column.id + 'list'}
        />
        <div className="h-1" />
    </>
    )
}

function DebouncedInput({value: initialValue, onChange, debounce = 500, ...props}) {
    const [value, setValue] = useState(initialValue)
    useEffect(() => {
        setValue(initialValue)
    }, [initialValue])
    useEffect(() => {
        const timeout = setTimeout(() => {
        onChange(value)
    }, debounce)
        return () => clearTimeout(timeout)
    }, [value])
    return (
        <input {...props} value={value} onChange={e => setValue(e.target.value)} className={'form-control form-control-sm bg-white text-black'}/>
    )
}

export default PosterListTable